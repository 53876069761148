import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Common/Layout"
import Seo from "../components/Common/Seo"

import { Container, Row, Col, Button} from "react-bootstrap";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <section>
        <Container>
          <Row className="justify-content-center mt-5 pt-5">
            <Col>
            <div>
                <div className="text-center">
                  <h1 className="display-2 fw-bold">NOT FOUND</h1>
                  <Link to="/">
                    <Button className="btn btn-primary btn-lg px-5 mt-4">
                      Go home
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </Layout>
  )
}

export default NotFoundPage
